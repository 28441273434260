import React, { useState } from 'react'

import Subscriptions from './subscriptions/Subscriptions'
import Devices from './Devices/Devices'
import Logs from './Logs/Logs'
import Users from './Users/Users'

const menuTabs = [
    {
        label: "Transactions",
        key: "transactions",
        component: <Subscriptions />
    },
    {
        label: "Devices",
        key: "devices",
        component: <Devices />
    },
    {
        label: "Logs",
        key: "logs",
        component: <Logs />
    }
    ,
    {
        label: "Users",
        key: "users",
        component: <Users />
    }
]

function DataTablesMenu () {
    const [activeTab, setActiveTab] = useState(menuTabs[0])

    return (
        <div className="content-wrapper">
            {/* Content */ }
            <div className="mx-md-2 flex-grow-1 pb-0 container-p-y ">
                <div className="row">
                    <div className="col-md-10 px-3">
                        <ul className="nav nav-pills flex-column flex-md-row mb-3">
                            {
                                menuTabs.map((tab, index) => {

                                    return (
                                        <li className="nav-item me-2" onClick={ () => {
                                            setActiveTab({
                                                key: tab.key,
                                                component: tab.component
                                            })
                                        } }
                                            key={ index }>
                                            <a className={ `nav-link ${ activeTab.key == tab.key && "active" }` } href="#">{ tab.label }</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="col-2 d-flex  align-items-start justify-content-end">
                        <button className={ `nav-link btn btn-primary ` }  onClick={ () => window.history.back() }> Back</button>
                    </div>
                    <div div className="col-md-12 ">
                        { activeTab.component }
                    </div>
                </div>
            </div>

            <div className="content-backdrop fade" />
        </div>
    )
}

export default DataTablesMenu
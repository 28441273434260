import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRouter, ProtectedRouterTwo } from "./ProtectedRouter";

import Login from "./pages/Auth/Login";
import Dashboard from "./pages/Board/Dashboard";

import UserDatatable from "./pages/Users/Datatable";

import DeviceDatatable from "./pages/Devices/Datatable";
import DeviceShow from "./pages/Devices/Component/Show";

import Reports from "./pages/Reports/Reports";
import NotFound from "./pages/Layout/NotFound";
import "./App.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "./index.css";
import './assets/vendor/css/pages/page-auth.css';

import Account from "./pages/Users/Account";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import ForgetPassword from "./pages/Auth/ResetPassword/ForgetPassword";
import LogoutProcess from "./pages/Auth/Logout/LogoutProcess";
import CompaniesDataTable from "./pages/Company/DataTable";
import PackagesDataTable from "./pages/Packages/PackagesDataTable";
import { PackageProvider } from "./pages/Contexts/PackagesContext";
import { GlobalProvider } from "./pages/Contexts/GlobalContext";
import CompanyProfile from "./pages/Company/components/CompanyProfile";
import { CompaniesProvider } from "./pages/Contexts/CompaniesContext";

export default function App () {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Routes>

          <Route path="/" element={ <Login /> } />

          <Route element={ <ProtectedRouter /> }>
            <Route path="/dashboard" element={ <Dashboard /> } />
            <Route path="/profile" element={ <Account /> } />
            <Route path="/reports" element={ <Reports /> } />

            <Route group="clients">
              <Route path="/clients" element={ <CompaniesProvider> <CompaniesDataTable />  </CompaniesProvider> } />
              <Route path="/companies/:id/show" element={ <CompaniesProvider>  <CompanyProfile /> </CompaniesProvider> } />
            </Route>


            <Route group="users">
              <Route path="/users/list" element={ <UserDatatable /> } />
            </Route>

            <Route group="packages">
              <Route path="/packages/list" element={ <PackageProvider><PackagesDataTable /></PackageProvider> } />
            </Route>

            <Route group="devices">
              <Route path="/devices/list" element={ <DeviceDatatable /> } />
              <Route path="/devices/:id/show" element={ <DeviceShow /> } />
            </Route>

          </Route>

          <Route element={ <ProtectedRouterTwo /> }>
            <Route path="/forget/password" element={ <ForgetPassword /> } />
            <Route path="/reset/password/:token" element={ <ResetPassword /> } />
          </Route>
          <Route path="/logout/process" element={ <LogoutProcess /> } />
          <Route path="*" element={ <NotFound /> } />
        </Routes>
      </BrowserRouter>
    </GlobalProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

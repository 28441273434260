import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import { useCompaniesContext } from '../../../../Contexts/CompaniesContext';
import { useGlobalContext } from '../../../../Contexts/GlobalContext';
import { NetworkManager } from '../../../../../config/NetworkManager';
import LogPreview from './LogPreview';

function Logs() {
  const { subLoaders, loadingHandler, openDialog, dialogHandler } = useGlobalContext();
  const { selectedLog, setSelectedLog } = useCompaniesContext();

  const { id } = useParams();

  const [totalRecords, setTotalRecords] = useState(0);
  const [logs, setLogs] = useState(null);

  const Service = new NetworkManager();
  let loadLazyTimeout = null;

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: 1,
    filters: {
      'action': { value: '', matchMode: 'contains' },
      'model': { value: '', matchMode: 'contains' },
    },
    isInteractive: 1,
    company_id: id
  });

  //Data Table Handlers
  const loadLazyData = () => {
    if (!subLoaders.logs)
      loadingHandler("logs");

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      Service.datatable('logs', lazyParams)
        .then(data => {
          if (subLoaders.reload)
            loadingHandler("reload");
          setTotalRecords(data.pagination.total);
          setLogs(data.data);
          loadingHandler("logs");
        });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    event['filters'] = {};
    event['company_id'] = id;
    setLazyParams(event);
  }

  const onFilter = (event) => {
    event['first'] = 0;
    event['company_id'] = id
    setLazyParams(event);
  }

  const onSort = (event) => {
    event['company_id'] = id
    setLazyParams(event);
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams])

  useEffect(() => {
    if (subLoaders.reload)
      loadLazyData();
  }, [subLoaders.reload])

  const previewHandler = (rowData) => {
    setSelectedLog(rowData)
    dialogHandler("logPreview")
  }

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className="d-inline-block text-nowrap">
        {/* Show Log's data */}
        <Tooltip target={`.show-button-${rowData.id}`} showDelay={100} className="fs-8" />
        <button className={`btn btn-sm btn-icon show-button-${rowData.id}`}
          data-pr-position="bottom"
          data-pr-tooltip="show"
          onClick={() => previewHandler(rowData)}>
          <i class="fas fa-eye"></i>
        </button>
      </div>
    )
  }


  return (
    <div className="card">
      <div className='table-responsive text-nowrap stream_datatable position-relative'>
        <DataTable
          value={logs}
          lazy
          filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          paginator
          first={lazyParams.first}
          rows={lazyParams.rows}
          rowsPerPageOptions={[10, 25, 50, 100]}
          totalRecords={totalRecords}
          onPage={onPage}
          onSort={onSort}
          onFilter={onFilter}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          filters={lazyParams.filters}
          loading={subLoaders.logs}
          className="table text-light"
          scrollable scrollHeight="54vh"

        >
          <Column field="id" header="Log ID" className='text-center fs-6' />
          <Column field="user_name" header="User  Name" className='text-center fs-6' />
          <Column field="model" header="Model" className=' text-center fs-6' sortable filter />
          <Column field="action" header="Action" className='text-capitalize  text-center fs-6' sortable filter />
          <Column field="created_at" header="Creation date" className='text-center fs-6' sortable />
          {/* <Column field="" header="  " className='text-center fs-6' body={ actionsBodyTemplate } /> */}
        </DataTable>

      </div>
      {openDialog.logPreview ? <LogPreview /> : <></>}
    </div>
  )
}

export default Logs

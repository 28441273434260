import React, { useRef } from 'react';
import { Link } from 'react-router-dom'

import { useForm, Controller } from "react-hook-form";
import { useTitle, getFormErrorMessage } from '../../../config/global';

import landing from '../../../assets/img/boy-with-rocket-light.png';
import logo from '../../../assets/img/logo.png';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import axiosApiInstance from '../../../config/AxiosInstances/AxiosApiInstance';


function ForgetPassword () {
    useTitle('Reset Password');

    const { formState: { errors }, handleSubmit, control } = useForm();
    const { showToast } = useGlobalContext();

    const [validEmail, setValidEmail] = useState(false);

    const onSubmit = (data) => {
        axiosApiInstance.post(`/users/forget-password`, data)
            .then((result) => {
                setValidEmail(true)
            })
            .catch((error) => {
                showToast('error', 'Forget Password', error.response?.data?.message)
            });
    };

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                        <div className="w-100 d-flex justify-content-center">
                            <img src={ landing } className="img-fluid" width={ 700 } alt="landing" />
                        </div>
                    </div>
                    <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            {/* Logo */ }
                            <div className="app-brand mb-5">
                                <Link to="/" className="app-brand-link gap-2" style={ { margin: "auto" } } >
                                    <img src={ logo } className="img-fluid" width={ 200 } alt="logo" />
                                </Link>
                            </div>
                            <div className='mt-5 '>
                                { validEmail ?
                                    <>
                                        <h4 className=' text-center'>Password reset email has been sent.</h4>
                                        <p>A password reset email has been sent to the email address on file for your account,
                                            but may take several minutes to show up in your inbox. Please wait at
                                            least 5 minutes before attempting another reset.
                                        </p>
                                    </>
                                    :
                                    <>
                                        <h4 >Forget Password</h4>
                                        <form onSubmit={ handleSubmit(onSubmit) } className="mb-3 ">
                                            <div className="field my-4">
                                                <label className="form-label" htmlFor="basic-default-fullname">Email</label>
                                                <span className="p-float-label">
                                                    <Controller name="email" control={ control }
                                                        rules={ {
                                                            required: 'Email Address is required.',
                                                            pattern: {
                                                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                                message: "Invalid email address",
                                                            }
                                                        } }
                                                        render={ ({ field, fieldState }) => (
                                                            <InputText id={ field.email } { ...field } autoFocus
                                                                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                        ) } />
                                                </span>
                                                { getFormErrorMessage('email', errors) }
                                            </div>
                                            <div className='d-flex justify-content-center my-3'>
                                                <button className="btn btn-primary d-grid w-100 ">Send</button>
                                            </div>
                                        </form>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgetPassword
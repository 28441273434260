import React, { useState, useEffect } from "react";
import axiosAdminInstance from "../../config/AxiosInstances/AxiosAdminInstance";

import { useTitle } from "../../config/global";
import Layout from "../Layout/Layout";

const Dashboard = () => {
  useTitle("Dashboard Page");
  const [data, setData] = useState({});
  const [invoiceStats, setInvoiceStats] = useState({});
  const [filter, setFilter] = useState("allTime");
  const invoiceTypes = ["GIFT", "STRIP", "TRIAL", "BANK"];

  useEffect(() => {
    axiosAdminInstance.get(`/dashboard`)
      .then((response) => {
        setData(response?.data?.data);
        setInvoiceStats(response?.data?.data?.invoiceStatistics || {});
      })
  }, []);

  const [chartData] = useState({
    datasets: [
      {
        data: [10, 8],
        backgroundColor: ["#0275d8", "#292b2c"],
        label: "Devices",
      },
    ],
    labels: ["Online", "Offline"],
  });

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      r: {
        grid: {
          color: "#ebedef",
        },
      },
    },
  });

  const filterOptions = [
    { label: "This Week", value: "weekly" },
    { label: "This Month", value: "monthly" },
    { label: "This Year", value: "lastYear" },
    { label: "All Time", value: "allTime" },
  ];

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="px-0 flex-grow-1 ">
          <div className="row">
            <div className="col-lg-6 mb-1 order-0 mb-4">
              <div className="card">
                <div className="d-flex align-items-end row">
                  <div className="col-sm-6 mb-4">
                    <div className="card-body mt-2">
                      <h5 className="card-title text-primary text-capitalize">
                        Welcome {localStorage.getItem("name")}
                      </h5>
                      <p className="mb-4">
                        Start managing your screens and content from this dashboard.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 text-center text-sm-left">
                    <div className="card-body pb-0 px-0 px-md-1 ms-5">
                      <img
                        src={require("../../assets/img/man-with-laptop-light.png")}
                        className="dashboard_img"
                        height={140}
                        alt="View Badge User"
                        data-app-light-img="illustrations/man-with-laptop-light.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 order-1">
              <div className="row">
                <div className="col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-primary">
                          <i className="bx bx-mobile-alt"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Total Devices</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        {data?.devices}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-warning">
                          <i class="fas fa-users"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Total Users</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        {data?.users}
                      </h4>
                    </div>
                  </div>
                </div>
                {/* <div className="col-4 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-dark">
                          <i className="bx bx-mobile-alt"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Offline Screen</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        { data?.totalOfflineDevice }
                      </h4>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

          </div>
          {/* Tab Menu */}
          <div className="row mb-4">
            <div className="col-12">
              <ul className="nav nav-tabs">
                {filterOptions.map((option) => (
                  <li className="nav-item" key={option.value}>
                    <button
                      className={`nav-link ${filter === option.value ? "active" : ""}`}
                      onClick={() => handleFilterChange(option.value)}
                    >
                      {option.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Invoice Cards */}
          <div className="row">
            {invoiceTypes.map((type) => (
              <div className="col-lg-3 col-md-6 mb-4" key={type}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="fw-semibold mt-4">{type} Invoices</h5>
                    <h4 className="card-title">
                      {invoiceStats[filter]?.find((stat) => stat.type === type)?.count || 0}
                    </h4>
                    <p className="text-muted">
                      Total Paid: $
                      {(invoiceStats[filter]?.find((stat) => stat.type === type)?.totalPaid)?.toFixed(2) || 0}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Dashboard;

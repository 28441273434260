import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import { useTitle, dateFormate } from '../../config/global';
import { useGlobalContext } from '../Contexts/GlobalContext';
import axiosAdminInstance from '../../config/AxiosInstances/AxiosAdminInstance';
import DeleteConfirmation from '../SharedComponents/DeleteConfirmation';
import { NetworkManager } from '../../config/NetworkManager';
import Layout from "../Layout/Layout";
import Update from './Component/Update';

let emptyDevice = {
	name: "",
	serial_name: ""
};

const DeviceDatatable = () => {
	useTitle('Devices');
	const { showToast, setDisableBtn, openDialog, dialogHandler } = useGlobalContext();
	const [Device, setDevice] = useState(emptyDevice);

	// Data table
	const [totalRecords, setTotalRecords] = useState(0);
	const [devices, setDevices] = useState(null);

	const [loading, setLoading] = useState(false);
	const [rerender, setRerender] = useState(false);
	const [updateOnline, setUpdateOnline] = useState(false);

	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 15,
		page: 0,
		sortField: null,
		sortOrder: 1,
		filters: {
			'serial_name': { value: '', matchMode: 'contains' },
			'name': { value: '', matchMode: 'contains' },
			'is_online': { value: '', matchMode: 'contains' },
			'status': { value: '', matchMode: 'contains' },
			'created_at': { value: '', matchMode: 'contains' },
		}
	});

	const Service = new NetworkManager();
	let loadLazyTimeout = null;

	// DataTable Handler (fetch data)
	const loadLazyData = () => {
		if (!updateOnline)
			setLoading(true);

		if (loadLazyTimeout) {
			clearTimeout(loadLazyTimeout);
		}

		//imitate delay of a backend call
		loadLazyTimeout = setTimeout(() => {
			Service.datatable('device', lazyParams).then(data => {
				setTotalRecords(data.pagination.total);
				setDevices(data.data);
				if (!updateOnline)
					setLoading(false);
				setRerender(false);
				setUpdateOnline(false)
			});
		}, Math.random() * 1000 + 250);
	}

	const onPage = (event) => {
		document.documentElement.scrollTop = 0;
		setLazyParams(event);
	}

	const onSort = (event) => {
		setLazyParams(event);
	}

	const onFilter = (event) => {
		event['first'] = 0;
		setLazyParams(event);
	}

	// Data table Logic
	useEffect(() => {
		loadLazyData();
	}, [lazyParams])

	useEffect(() => {
		if (rerender) {
			loadLazyData();
		}
	}, [rerender])

	const deleteDevice = () => {
		axiosAdminInstance.delete(`/device/${ Device.id }`)
			.then(result => {
				dialogHandler("deleteDevice");
				setRerender(true)
				showToast('success', 'Delete Device', result?.data?.data?.msg)
			});
		setDisableBtn(false)
	}

	const openEditDialog = (data) => {
		setDevice(data);
		dialogHandler("updateDevice");
	}

	// Data table body column's templates
	const actionBodyTemplate = (rowData) => {
		return (
			<div className="d-inline-block text-nowrap">
				{/* Edit */ }
				<Tooltip target={ `.edit-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
				<button className={ `btn btn-sm btn-icon edit-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="Update" onClick={ () => { openEditDialog(rowData) } }>
					<i className="pi pi-pencil"></i>
				</button>
			</div>
		);
	}

	const statusBodyTemplate = (rowData) => {
		return (
			<div className='text-center'>
				<span className={ `text-center badge ${ rowData.status == "ACTIVE" ? "bg-label-success" : (rowData.status == "EXPIRED" ? "bg-label-danger" : "bg-label-warning") }` }>{ rowData.status.toLowerCase() }</span>
			</div>
		);
	}

	const statusOnlineTemplate = (rowData) => {
		return (
			<div className='text-center'>
				{
					rowData.is_online == 1 ?
						<span className="text-center badge bg-label-success">OnLine</span>
						:
						<span className="text-center badge bg-label-danger">OFF-Line</span>
				}
			</div>
		)
	}

	const rotationBodyTemplate = (rowData) => {
		return (
			<span className="badge badge-center bg-label-secondary">
				{ rowData.rotation } <sup>°</sup>
			</span>
		);
	}

	const dateBodyTemplate = (rowData) => {
		return dateFormate(rowData.created_at);
	}

	return (
		<Layout>
			<div className="card">
				<div className="card-header d-flex align-items-center justify-content-between">
					<h5 className="card-title m-0 me-2">Devices</h5>
					{/* <button type="button" className="btn btn-primary me-sm-3 me-1" onClick={ openCreateDialog }>
						<span className="tf-icons bx bx-device"></span> New Device
					</button> */}
				</div>
				<div className='table-responsive text-nowrap'>
					<DataTable value={ devices } lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
						paginator first={ lazyParams.first } rows={ lazyParams.rows } rowsPerPageOptions={ [15, 25, 50, 100] }
						totalRecords={ totalRecords } onPage={ onPage }
						onSort={ onSort } sortField={ lazyParams.sortField } sortOrder={ lazyParams.sortOrder }
						onFilter={ onFilter } filters={ lazyParams.filters } loading={ loading } className="table"
						scrollable scrollHeight="68vh"
					>
						<Column field="serial_name" header="Serial" sortable filter />
						<Column field="name" header="Name" sortable filter />
						<Column field="company_name" header="CompanyName" />
						<Column
							field="is_online"
							header="Is Online"
							body={ statusOnlineTemplate }
							sortable
							style={ { textAlign: 'center' } } />
						<Column
							field="rotation"
							header="Rotation"
							style={ { textAlign: 'center' } }
							body={ rotationBodyTemplate }
							sortable
						/>
						<Column
							header="status"
							filterField="status"
							body={ statusBodyTemplate }
							showFilterMatchModes={ false }
							sortable
						/>
						<Column
							header="Created Date"
							filterField="created_at"
							dataType="date"
							body={ dateBodyTemplate }
							style={ { textAlign: 'center' } }
						/>
						<Column
							body={ actionBodyTemplate }
							exportable={ false }
							style={ { minWidth: '8rem' } }
							sortable
						/>
					</DataTable>
				</div>
			</div>

			{ openDialog.deleteDevice && <DeleteConfirmation deleteHandler={ deleteDevice } itemName={ Device.name ?? Device.serial_name } /> }
			{ openDialog.updateDevice && <Update setRerender={ setRerender } device={ Device } /> }
		</Layout>
	);
}
export default DeviceDatatable;

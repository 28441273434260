import React from 'react';

import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import axiosApiInstance from '../../../config/AxiosInstances/AxiosApiInstance';

const LogoutProcess = () => {
  const { showToast } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(
    () => {
      axiosApiInstance.get(`/users/logout`)
        .then(response => {
          showToast('success', 'Logout', response?.data?.data?.msg);
        })

      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("image");
      localStorage.removeItem("phone");
      localStorage.removeItem("access_token");
      localStorage.removeItem("email_verified_at");

      navigate("/");
    }, [])

  return null
}

export default LogoutProcess 
import React from 'react';

import { Controller } from "react-hook-form";

import { getFormErrorMessage, } from '../../../../config/global';

import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import { usePackageContext } from '../../../Contexts/PackagesContext';

const Features = [
  "full_player_offline",
  "advance_content_schedule",
  "published_as_grouping",
  "content_preview",
  "prayer_app",
  "music_list_access",
  "ai_access",
  "live_meeting_access",
  "interactive_action",
  "add_users",
  "3d",
  "rss_feed",
  "layouts" ,

];

function Step2 ({ control, errors, setError, clearErrors }) {

  const { selectedFeature, setSelectedFeature } = usePackageContext();

  const onFeaturesChange = (e) => {
    let _selectedFeature = [...selectedFeature];

    if (e.checked)
      _selectedFeature.push(e.value);
    else
      _selectedFeature.splice(_selectedFeature.indexOf(e.value), 1);


    if (_selectedFeature.length > 0) {
      clearErrors("setting")
    } else {
      setError('setting', { type: 'manual', message: 'Select package setting!' });
    }

    setSelectedFeature(_selectedFeature);
  }

  return (
    <>
      <div className="col-12  d-flex mb-3 flex-wrap">
        {
          Features.map((feature_name, index) => {
            return (
              <div key={ index } className='col-6 d-flex mb-3'>
                <div className="field me-2 d-flex align-items-center ">
                  <span className="p-float-label me-2">
                    <Controller name={ `${ feature_name }` } control={ control }
                      rules={ { required: false } }
                      render={ ({ field, fieldState }) => (
                        <Checkbox id={ field.name }
                          name="features"
                          { ...field }
                          value={ `${ feature_name }` }
                          inputRef={ field.ref }
                          checked={ selectedFeature.indexOf(`${ feature_name }`) !== -1 }
                          onChange={ onFeaturesChange }
                          className={ `${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                          inputId={ feature_name + "-" + index }
                        />
                      ) } />
                  </span>
                  <label className="text-capitalize fs-6 cursor-pointer" htmlFor={ feature_name + "-" + index }>{ feature_name.replace(/_/g, " ") }</label>
                </div>
              </div>
            )
          })
        }
        { getFormErrorMessage("setting", errors) }
      </div>
    </>
  )
}

export default Step2
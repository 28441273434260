import React, { useState, useEffect } from 'react';
import { useForm, } from 'react-hook-form';

import { Steps } from 'primereact/steps';
import { Button } from 'primereact/button';
import { Spinner } from 'react-bootstrap';

import axiosAdminInstance from '../../../../config/AxiosInstances/AxiosAdminInstance';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { usePackageContext } from '../../../Contexts/PackagesContext';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

function FormWizard () {
    const { control, formState: { errors, isValid }, trigger, handleSubmit, setError, clearErrors, reset } = useForm({ mode: 'onChange' });
    const { disableBtn, setDisableBtn, setRerender, dialogHandler, showToast } = useGlobalContext();
    const {
        selectedFeature, setSelectedFeature,
        selectedMediaTypes, setSelectedMediaTypes,
        selectedLayers, setSelectedLayers,
        selectedPeriods, setSelectedPeriods,
        selectedPackage, defaultPeriods,
        setPeriodPrices
    } = usePackageContext();

    const [activeStep, setActiveStep] = useState(0);
    const [isCreation, setIsCreation] = useState(Object.keys(selectedPackage).length > 0 ? false : true);
    const [showInDashboard, setShowInDashboard] = useState(false);

    const steps = [
        { label: 'Plan Setup' },
        { label: 'Settings' },
        { label: 'Media' },
        { label: 'Pricing' },
    ];

    const nextStep = () => {

        if (activeStep == 0) {
            let firstStepInputs = [
                "title",
                "upload_once_content_capacity",
                "media_storage_capacity",
                "orientation",
                "playlist_capacity"
            ]

            firstStepInputs.map(async (fieldName) => {
                await trigger(fieldName);
            })

            if (isValid)
                setActiveStep(prev => prev + 1)
        }

        if (activeStep == 1) {
            if (selectedFeature.length == 0) {
                setError('setting', { type: 'manual', message: 'Select package setting!' });
            }
            else {
                setActiveStep(prev => prev + 1)
            }
        }


        if (activeStep == 2) {
            if (selectedLayers.length == 0) {
                setError('layers', { type: 'manual', message: 'One Layer at least is required!' });
            }

            if (selectedMediaTypes.length == 0) {
                setError('media_types', { type: 'manual', message: 'Please, select allowed media type! ' });
            }

            if (selectedLayers.length > 0 && selectedMediaTypes.length > 0) {
                setActiveStep(prev => prev + 1)
            }
        }

    };

    const prevStep = () => {
        setActiveStep(prev => prev - 1);
    };

    const onSubmit = (data) => {
        if (!isCreation)
            var url = `/package/${ selectedPackage.id }/update`
        else
            var url = `/package/create`

        let formData = {
            title: data.title,
            show_in_dashboard: showInDashboard,
            features: {
                "advance_content_schedule": selectedFeature.indexOf("advance_content_schedule") !== -1 ? true : false,
                "published_as_grouping": selectedFeature.indexOf("published_as_grouping") !== -1 ? true : false,
                "live_meeting_access": selectedFeature.indexOf("live_meeting_access") !== -1 ? true : false,
                "full_player_offline": selectedFeature.indexOf("full_player_offline") !== -1 ? true : false,
                "interactive_action": selectedFeature.indexOf("interactive_action") !== -1 ? true : false,
                "music_list_access": selectedFeature.indexOf("music_list_access") !== -1 ? true : false,
                "content_preview": selectedFeature.indexOf("content_preview") !== -1 ? true : false,
                "prayer_app": selectedFeature.indexOf("prayer_app") !== -1 ? true : false,
                "ai_access": selectedFeature.indexOf("ai_access") !== -1 ? true : false,
                "add_users": selectedFeature.indexOf("add_users") !== -1 ? true : false,
                "rss_feed": selectedFeature.indexOf("rss_feed") !== -1 ? true : false,
                "3d": selectedFeature.indexOf("3d") !== -1 ? true : false,
                "layouts": selectedFeature.indexOf("layouts") !== -1 ? true : false,
                "upload_once_content_capacity": data.upload_once_content_capacity,
                "media_storage_capacity": data.media_storage_capacity,
                "playlist_capacity": data.playlist_capacity,
                "multiple_layer": selectedLayers,
                "orientation": data.orientation,
                "media_type": selectedMediaTypes,
            },
            periods: selectedPeriods
        }

        axiosAdminInstance.post(url, formData)
            .then((result) => {
                dialogHandler("createPackage")
                setDisableBtn(false)
                setRerender(true)
                showToast('success', ' Package Creation', 'Package created successfully!');
                setSelectedFeature([])
                setSelectedMediaTypes([])
                setSelectedLayers([])
                setSelectedPeriods(defaultPeriods)
            }).catch((error) => {
                setDisableBtn(false);
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', ' Package Creation', error[0]);
                });
            });
    }

    // Set form date to update
    useEffect(() => {
        if (!isCreation) {
            let features = selectedPackage?.features;
            const pricing = {};
            var sixMonthDiscount = 0;
            var oneYearDiscount = 0;

            setShowInDashboard(selectedPackage?.show_in_dashboard)

            const newData = selectedPackage?.prices.map((feature) => {
                pricing[feature.period.toLowerCase()] = feature.price;

                if (feature.period.toLowerCase() == "six_months")
                    sixMonthDiscount = feature.discount_percentage;
                else if (feature.period.toLowerCase() == "one_year")
                    oneYearDiscount = feature.discount_percentage;

                return {
                    percentage: feature.discount_percentage,
                    period: feature.period.toLowerCase() == "one_month" ? 1 : feature.period.toLowerCase() == "six_months" ? 6 : 12,
                    price: feature.price,
                    name: feature.period.toLowerCase(),
                    id: feature.id
                }
            });

            setSelectedPeriods(newData)
            setPeriodPrices(pricing);

            reset({
                title: selectedPackage?.title,
                show_in_dashboard: selectedPackage?.show_in_dashboard,
                upload_once_content_capacity: parseInt(features?.upload_once_content_capacity),
                media_storage_capacity: parseInt(features?.media_storage_capacity),
                playlist_capacity: parseInt(features?.playlist_capacity),
                orientation: features?.orientation,
                price: pricing["one_month"],
                six_months_price: sixMonthDiscount,
                one_year_price: oneYearDiscount,
                "3d": features["3d"]
            });

            const _features = Object.keys(features).filter((key) => features[key] == true);
            setSelectedFeature(_features)
            setSelectedMediaTypes(features?.media_type)
            setSelectedLayers(features?.multiple_layer)
        }
    }, [reset])

    return (
        <form onSubmit={ handleSubmit(onSubmit) } className="d-flex flex-column justify-content-center  " >

            <div className='col-11 px-3 my-4 d-flex flex-column mx-auto'>
                <Steps model={ steps } activeIndex={ activeStep } onSelect={ (e) => setActiveStep(e.index) } />
            </div>

            <div className=' mb-4 d-flex flex-column col-10 mx-auto p-4 bg_light rounded'>
                { activeStep === 0 && <Step1 isCreation={ isCreation } control={ control } errors={ errors } trigger={ trigger } showInDashboard={ showInDashboard } setShowInDashboard={ setShowInDashboard } /> }
                { activeStep === 1 && <Step2 control={ control } errors={ errors } clearErrors={ clearErrors } setError={ setError } /> }
                { activeStep === 2 && <Step3 control={ control } errors={ errors } clearErrors={ clearErrors } setError={ setError } /> }
                { activeStep === 3 && <Step4 control={ control } errors={ errors } isCreation={ isCreation } /> }
            </div>

            <div className="col-12  d-flex justify-content-center py-3 border-top ">
                <Button label="Previous" type='button' disabled={ activeStep == 0 ? true : false } className="btn btn-primary me-2  p-button-sm w-auto" icon="pi pi-chevron-left" onClick={ prevStep } />
                {
                    activeStep < steps.length - 1 &&
                    <Button label="Next" type='button' className="btn btn-primary me-2  p-button-sm  w-auto" icon="pi pi-chevron-right" onClick={ nextStep } />
                }
                {
                    activeStep == 3 &&
                    <button type="submit" className="btn btn-primary me-2" disabled={ disableBtn ? true : false }>
                        {
                            disableBtn ?
                                <Spinner as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                isCreation ? "Create" : "Update"
                        }
                    </button>
                }
            </div>
        </form>

    )
}

export default FormWizard